import { useEffect, useRef } from "react"
import axios from "axios"

/* BASE URL */
export const siteURL = "https://mensetsubot.miraic.com"
// export const siteURL = "http://localhost:8080"
export const baseURL = siteURL + "/api"
export const mediaURL = siteURL + "/imageuploads/"

/* FULLCALENDAR KEY */
export const FKEY = "CC-Attribution-NonCommercial-NoDerivatives"
export const BUSINESS_OPEN_TIME = "12:00"
export const BUSINESS_CLOSE_TIME = "23:00"
export const BOOKING_OPEN_TIME = "12:00"
export const BOOKING_CLOSE_TIME = "23:00"
export const BUSINESS_INTERVAL_TIME = "01:00"

/* NOTIFICATION */
export const CALL_TITLE = "電話リクエスト"
export const CALL_MSG = `申込者に${CALL_TITLE}を送りました`
export const CALL_IDENTIFIER = "[call]"
export const NOTIFY_TITLE = "再通知"
export const NOTIFY_MSG = `申込者に${NOTIFY_TITLE}を送りました`
export const NOTIFY_IDENTIFIER = "[bell]"

/* REDIRECT URL */
export const loginURL = "/login"
export const rootURL = "/home"

/* BACKEND API URLS */
export const apiCheckSession = baseURL + "/sess"
export const apiLogin = baseURL + "/login"
export const apiLogout = baseURL + "/logout"
export const apiRegistrations = baseURL + "/master/registrations"
export const apiChats = baseURL + "/master/chats"
export const apiTemplates = baseURL + "/master/templates"
export const apiCalls = baseURL + "/master/calls"
export const apiAdmins = baseURL + "/master/admins"
export const apiCertificate = baseURL + "/master/certificate"
export const apiAppointments = baseURL + "/master/appointments"
export const apiQualifiers = baseURL + "/master/qualifiers"

/* ROUTES */
export const loginRoute = "/login"
export const homeRoute = "/home"
export const scheduleRoute = "/schedule"
export const settingsRoute = "/settings"
export const notFoundRoute = "/not-found"

/* ALERT MESSAGES */
export const errorLoginMismatchMsg =
  "メールアドレスまたはパスワードが間違っています"
export const errorSystemMsg = "システムエラー"
export const errorSessionMsg = "もう一度ログインしてください"
export const errorPermissionMsg = "許可が足りない"
export const error404Msg =
  "申し訳ありませんが、アクセスしたページは存在しません。"
export const errorScheduleExistMsg =
  "この申込者に面接時間時間がもう登録されています"
export const successLoginMsg = "ログイン成功"
export const successLogoutMsg = "ログアウト成功"
export const successCreateMsg = "登録成功"
export const successUpdateMsg = "変更成功"
export const successSaveMsg = "保存成功"
export const successDeleteMsg = "削除成功"
export const successSentMsg = "送信成功"

/* TEMPLATE TYPES */
export const passTemplate = "pass"
export const failTemplate = "fail"
export const faqTemplate = "faq"
export const acceptTemplate = "accept"
export const rejectTemplate = "reject"
export const requestTemplate = "request"
export const remindMsgTemplate = "remindmsg"
export const remindCallTemplate = "remindcall"
export const appSavedTemplate = "appsaved"
export const certAcceptTemplate = "certaccept"
export const certAcceptOptionalTemplate = "certaccept1"
export const doneBasicTemplate = "donebasic"
export const reNotifyTemplate = "renotify"

/* AXIOS CONFIG */
export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
})

/* CHECK MOUNTED REACT */
export function useIsMountedRef() {
  const isMountedRef = useRef(null)

  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })

  return isMountedRef
}
