import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
  Row,
  Divider,
} from "antd"
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons"
import moment from "moment"
import "moment/locale/ja"
import * as Commons from "common/common"

moment.locale("ja")

const Settings = props => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const isMountedRef = Commons.useIsMountedRef()
  const [passedForm] = Form.useForm()
  const [failedForm] = Form.useForm()
  const [faqForm] = Form.useForm()
  const [acceptForm] = Form.useForm()
  const [rejectForm] = Form.useForm()
  const [requestForm] = Form.useForm()
  const [appSavedForm] = Form.useForm()
  const [remindMsgForm] = Form.useForm()
  const [remindCallForm] = Form.useForm()
  const [certAcceptForm] = Form.useForm()
  const [certAcceptOptionalForm] = Form.useForm()
  const [doneBasicForm] = Form.useForm()
  const [reNotifyForm] = Form.useForm()
  const [adminForm] = Form.useForm()

  const { Text } = Typography
  const { Option } = Select

  const [loadingSendSpin, setLoadingSendSpin] = useState(false)
  const [isPassModalVisible, setIsPassModalVisible] = useState(false)
  const [isFailModalVisible, setIsFailModalVisible] = useState(false)
  const [isFaqModalVisible, setIsFaqModalVisible] = useState(false)
  const [isAcceptModalVisible, setIsAcceptModalVisible] = useState(false)
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false)
  const [isAppSavedModalVisible, setIsAppSavedModalVisible] = useState(false)
  const [isRemindMsgModalVisible, setIsRemindMsgModalVisible] = useState(false)
  const [isRemindCallModalVisible, setIsRemindCallModalVisible] = useState(
    false,
  )
  const [isCertAcceptModalVisible, setIsCertAcceptModalVisible] = useState(
    false,
  )
  const [
    isCertAcceptOptionalModalVisible,
    setIsCertAcceptOptionalModalVisible,
  ] = useState(false)
  const [isDoneBasicModalVisible, setIsDoneBasicModalVisible] = useState(false)
  const [isReNotifyModalVisible, setIsReNotifyModalVisible] = useState(false)
  const [isAdminModalVisible, setIsAdminModalVisible] = useState(false)

  const [faqStatus, setFaqStatus] = useState({
    isNew: false,
  })
  const [passTemplate, setPassTemplate] = useState({})
  const [failTemplate, setFailTemplate] = useState({})
  const [faqTemplate, setFaqTemplate] = useState([])
  const [acceptTemplate, setAcceptTemplate] = useState({})
  const [rejectTemplate, setRejectTemplate] = useState({})
  const [requestTemplate, setRequestTemplate] = useState({})
  const [remindMsgTemplate, setRemindMsgTemplate] = useState({})
  const [remindCallTemplate, setRemindCallTemplate] = useState({})
  const [appSavedTemplate, setAppSavedTemplate] = useState({})
  const [certAcceptTemplate, setCertAcceptTemplate] = useState({})
  const [certAcceptOptionalTemplate, setCertAcceptOptionalTemplate] = useState(
    {},
  )
  const [doneBasicTemplate, setDoneBasicTemplate] = useState({})
  const [reNotifyTemplate, setReNotifyTemplate] = useState({})

  const [admins, setAdmins] = useState([])

  const showModal = type => {
    switch (type) {
      case Commons.passTemplate:
        passedForm.resetFields()
        setIsPassModalVisible(true)
        break
      case Commons.failTemplate:
        failedForm.resetFields()
        setIsFailModalVisible(true)
        break
      case Commons.acceptTemplate:
        acceptForm.resetFields()
        setIsAcceptModalVisible(true)
        break
      case Commons.rejectTemplate:
        rejectForm.resetFields()
        setIsRejectModalVisible(true)
        break
      case Commons.requestTemplate:
        requestForm.resetFields()
        setIsRequestModalVisible(true)
        break
      case Commons.remindMsgTemplate:
        remindMsgForm.resetFields()
        setIsRemindMsgModalVisible(true)
        break
      case Commons.remindCallTemplate:
        remindCallForm.resetFields()
        setIsRemindCallModalVisible(true)
        break
      case Commons.appSavedTemplate:
        appSavedForm.resetFields()
        setIsAppSavedModalVisible(true)
        break
      case Commons.certAcceptTemplate:
        certAcceptForm.resetFields()
        setIsCertAcceptModalVisible(true)
        break
      case Commons.certAcceptOptionalTemplate:
        certAcceptOptionalForm.resetFields()
        setIsCertAcceptOptionalModalVisible(true)
        break
      case Commons.doneBasicTemplate:
        doneBasicForm.resetFields()
        setIsDoneBasicModalVisible(true)
        break
      case Commons.reNotifyTemplate:
        reNotifyForm.resetFields()
        setIsReNotifyModalVisible(true)
        break
      default:
        break
    }
  }

  const showFaqModal = (isNew, id = undefined, content = undefined) => {
    faqForm.resetFields()
    setFaqStatus({ isNew: isNew, id: id })

    faqForm.setFieldsValue({
      faqReplyFormItem: content || "",
    })

    setIsFaqModalVisible(true)
  }

  const showAdminModal = () => {
    adminForm.resetFields()
    setIsAdminModalVisible(true)
  }

  const hideModal = type => {
    switch (type) {
      case Commons.passTemplate:
        setIsPassModalVisible(false)
        break
      case Commons.failTemplate:
        setIsFailModalVisible(false)
        break
      case Commons.acceptTemplate:
        setIsAcceptModalVisible(false)
        break
      case Commons.rejectTemplate:
        setIsRejectModalVisible(false)
        break
      case Commons.requestTemplate:
        setIsRequestModalVisible(false)
        break
      case Commons.remindMsgTemplate:
        setIsRemindMsgModalVisible(false)
        break
      case Commons.remindCallTemplate:
        setIsRemindCallModalVisible(false)
        break
      case Commons.appSavedTemplate:
        setIsAppSavedModalVisible(false)
        break
      case Commons.certAcceptTemplate:
        setIsCertAcceptModalVisible(false)
        break
      case Commons.certAcceptOptionalTemplate:
        setIsCertAcceptOptionalModalVisible(false)
        break
      case Commons.doneBasicTemplate:
        setIsDoneBasicModalVisible(false)
        break
      case Commons.reNotifyTemplate:
        setIsReNotifyModalVisible(false)
        break
      default:
        break
    }
  }

  const hideFaqModal = () => {
    setFaqStatus({ isNew: false })
    setIsFaqModalVisible(false)
  }

  const hideAdminModal = () => {
    setIsAdminModalVisible(false)
  }

  const showLoadingSendSpin = () => {
    setLoadingSendSpin(true)
  }

  const hideLoadingSendSpin = () => {
    setLoadingSendSpin(false)
  }

  const templateFormOnFinish = (data, template, type) => {
    if (isMountedRef.current) {
      showLoadingSendSpin()

      let paramData = {
        type: type,
      }

      switch (type) {
        case Commons.passTemplate:
          paramData.contentText = data.passedReplyFormItem
          break
        case Commons.failTemplate:
          paramData.contentText = data.failedReplyFormItem
          break
        case Commons.acceptTemplate:
          paramData.contentText = data.acceptReplyFormItem
          break
        case Commons.rejectTemplate:
          paramData.contentText = data.rejectReplyFormItem
          break
        case Commons.requestTemplate:
          paramData.contentText = data.requestReplyFormItem
          break
        case Commons.remindMsgTemplate:
          paramData.contentText = data.remindMsgReplyFormItem
          break
        case Commons.remindCallTemplate:
          paramData.contentText = data.remindCallReplyFormItem
          break
        case Commons.appSavedTemplate:
          paramData.contentText = data.appSavedReplyFormItem
          break
        case Commons.certAcceptTemplate:
          paramData.contentText = data.certAcceptReplyFormItem
          break
        case Commons.certAcceptOptionalTemplate:
          paramData.contentText = data.certAcceptOptionalReplyFormItem
          break
        case Commons.doneBasicTemplate:
          paramData.contentText = data.doneBasicReplyFormItem
          break
        case Commons.reNotifyTemplate:
          paramData.contentText = data.reNotifyReplyFormItem
          break
        default:
          break
      }

      if (template.templateId) {
        Commons.axiosInstance
          .put(Commons.apiTemplates + "/" + template.templateId, paramData)
          .then(response => {
            if (response.status === 200) {
              message.success(Commons.successUpdateMsg)
              fetchData(type)
            }
          })
          .catch(error => {
            if (error.response.status === 403) {
              message.warning(Commons.errorSessionMsg)
              history.push(Commons.loginURL)
            } else if (error.response.status === 500) {
              message.error(Commons.errorSystemMsg)
            }
          })
          .finally(() => {
            if (isMountedRef.current) {
              hideLoadingSendSpin()
              hideModal(type)
            }
          })
      } else {
        Commons.axiosInstance
          .post(Commons.apiTemplates, paramData)
          .then(response => {
            if (response.status === 200) {
              message.success(Commons.successCreateMsg)
              fetchData(type)
            }
          })
          .catch(error => {
            if (error.response.status === 403) {
              message.warning(Commons.errorSessionMsg)
              history.push(Commons.loginURL)
            } else if (error.response.status === 500) {
              message.error(Commons.errorSystemMsg)
            }
          })
          .finally(() => {
            if (isMountedRef.current) {
              hideLoadingSendSpin()
              hideModal(type)
            }
          })
      }
    }
  }

  const faqFormOnFinish = data => {
    if (isMountedRef.current) {
      showLoadingSendSpin()

      const paramData = {
        type: Commons.faqTemplate,
        contentText: data.faqReplyFormItem,
      }

      if (faqStatus.isNew) {
        Commons.axiosInstance
          .post(Commons.apiTemplates, paramData)
          .then(response => {
            if (response.status === 200) {
              message.success(Commons.successCreateMsg)
              fetchData(Commons.faqTemplate)
            }
          })
          .catch(error => {
            if (error.response.status === 403) {
              message.warning(Commons.errorSessionMsg)
              history.push(Commons.loginURL)
            } else if (error.response.status === 500) {
              message.error(Commons.errorSystemMsg)
            }
          })
          .finally(() => {
            if (isMountedRef.current) {
              hideLoadingSendSpin()
              hideFaqModal()
            }
          })
      } else {
        Commons.axiosInstance
          .put(Commons.apiTemplates + "/" + faqStatus.id, paramData)
          .then(response => {
            if (response.status === 200) {
              message.success(Commons.successUpdateMsg)
              fetchData(Commons.faqTemplate)
            }
          })
          .catch(error => {
            if (error.response.status === 403) {
              message.warning(Commons.errorSessionMsg)
              history.push(Commons.loginURL)
            } else if (error.response.status === 500) {
              message.error(Commons.errorSystemMsg)
            }
          })
          .finally(() => {
            if (isMountedRef.current) {
              hideLoadingSendSpin()
              hideFaqModal()
            }
          })
      }
    }
  }

  const adminFormOnFinish = data => {
    if (isMountedRef.current) {
      showLoadingSendSpin()

      const paramData = {
        adminIds: [data.adminFormItem],
      }

      Commons.axiosInstance
        .put(Commons.apiAdmins, paramData)
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successCreateMsg)
            fetchAdminData()
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingSendSpin()
            hideAdminModal()
          }
        })
    }
  }

  const handleDelete = (id, type) => {
    showLoadingPageSpin()

    Commons.axiosInstance
      .delete(Commons.apiTemplates + "/" + id)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successDeleteMsg)
          fetchData(type)
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleAdminDelete = id => {
    showLoadingPageSpin()

    Commons.axiosInstance
      .delete(Commons.apiAdmins + "/" + id)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successDeleteMsg)
          fetchAdminData()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const fetchData = useCallback(
    (type = undefined) => {
      showLoadingPageSpin()

      const params = {
        params: type ? { type: type } : {},
      }

      Commons.axiosInstance
        .get(Commons.apiTemplates, params)
        .then(response => {
          if (isMountedRef.current && response) {
            if (type) {
              switch (type) {
                case Commons.passTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setPassTemplate(response.data)
                  } else {
                    setPassTemplate({})
                  }

                  break
                case Commons.failTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setFailTemplate(response.data)
                  } else {
                    setFailTemplate({})
                  }

                  break
                case Commons.faqTemplate:
                  if (response.data && response.data.length > 0) {
                    setFaqTemplate(response.data)
                  } else {
                    setFaqTemplate([])
                  }

                  break
                case Commons.acceptTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setAcceptTemplate(response.data)
                  } else {
                    setAcceptTemplate({})
                  }

                  break
                case Commons.rejectTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setRejectTemplate(response.data)
                  } else {
                    setRejectTemplate({})
                  }

                  break
                case Commons.requestTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setRequestTemplate(response.data)
                  } else {
                    setRequestTemplate({})
                  }

                  break
                case Commons.remindMsgTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setRemindMsgTemplate(response.data)
                  } else {
                    setRemindMsgTemplate({})
                  }

                  break
                case Commons.remindCallTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setRemindCallTemplate(response.data)
                  } else {
                    setRemindCallTemplate({})
                  }

                  break
                case Commons.appSavedTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setAppSavedTemplate(response.data)
                  } else {
                    setAppSavedTemplate({})
                  }

                  break
                case Commons.certAcceptTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setCertAcceptTemplate(response.data)
                  } else {
                    setCertAcceptTemplate({})
                  }

                  break
                case Commons.certAcceptOptionalTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setCertAcceptOptionalTemplate(response.data)
                  } else {
                    setCertAcceptOptionalTemplate({})
                  }

                  break
                case Commons.doneBasicTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setDoneBasicTemplate(response.data)
                  } else {
                    setDoneBasicTemplate({})
                  }

                  break
                case Commons.reNotifyTemplate:
                  if (
                    response.data &&
                    Object.keys(response.data).length !== 0 &&
                    response.data.constructor === Object
                  ) {
                    setReNotifyTemplate(response.data)
                  } else {
                    setReNotifyTemplate({})
                  }

                  break
                default:
                  break
              }
            } else {
              if (response.data && response.data.length > 0) {
                response.data.forEach(template => {
                  switch (template.type) {
                    case Commons.passTemplate:
                      setPassTemplate(template)
                      break
                    case Commons.failTemplate:
                      setFailTemplate(template)
                      break
                    case Commons.faqTemplate:
                      setFaqTemplate(faqTemplate => [...faqTemplate, template])
                      break
                    case Commons.acceptTemplate:
                      setAcceptTemplate(template)
                      break
                    case Commons.rejectTemplate:
                      setRejectTemplate(template)
                      break
                    case Commons.requestTemplate:
                      setRequestTemplate(template)
                      break
                    case Commons.remindMsgTemplate:
                      setRemindMsgTemplate(template)
                      break
                    case Commons.remindCallTemplate:
                      setRemindCallTemplate(template)
                      break
                    case Commons.appSavedTemplate:
                      setAppSavedTemplate(template)
                      break
                    case Commons.certAcceptTemplate:
                      setCertAcceptTemplate(template)
                      break
                    case Commons.certAcceptOptionalTemplate:
                      setCertAcceptOptionalTemplate(template)
                      break
                    case Commons.doneBasicTemplate:
                      setDoneBasicTemplate(template)
                      break
                    case Commons.reNotifyTemplate:
                      setReNotifyTemplate(template)
                      break
                    default:
                      break
                  }
                })
              } else {
                setPassTemplate({})
                setFailTemplate({})
                setFaqTemplate([])
                setAcceptTemplate({})
                setRejectTemplate({})
                setRequestTemplate({})
                setRemindMsgTemplate({})
                setRemindCallTemplate({})
                setAppSavedTemplate({})
                setCertAcceptTemplate({})
                setCertAcceptOptionalTemplate({})
                setDoneBasicTemplate({})
                setReNotifyTemplate({})
              }
            }
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    },
    [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin],
  )

  const fetchAdminData = useCallback(() => {
    Commons.axiosInstance
      .get(Commons.apiAdmins)
      .then(response => {
        if (isMountedRef.current && response) {
          if (response.data && response.data.length > 0) {
            setAdmins(response.data)
          } else {
            setAdmins([])
          }
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
  }, [isMountedRef, history])

  useEffect(fetchData, [])
  useEffect(fetchAdminData, [])

  const columns = [
    {
      title: "ID",
      dataIndex: "templateId",
      align: "center",
      width: "5%",
    },
    {
      title: "メッセージ",
      dataIndex: "contentText",
      align: "center",
      width: "80%",
      render: (contentText, record) => (
        <Text className="whitespace-pre-wrap">{contentText}</Text>
      ),
    },
    {
      title: "行動",
      dataIndex: "action",
      align: "center",
      width: "15%",
      render: (template, record) => (
        <>
          <Button
            className="m-1"
            icon={<EditOutlined />}
            onClick={() =>
              showFaqModal(false, template.templateId, template.contentText)
            }
          >
            変更
          </Button>
          <Popconfirm
            title="削除してもよろしいですか?"
            onConfirm={() =>
              handleDelete(template.templateId, Commons.faqTemplate)
            }
            okText="削除"
            cancelText="閉じる"
            okType="danger"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button className="m-1" icon={<DeleteOutlined />} danger>
              削除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]

  const adminColumns = [
    {
      title: "ID",
      dataIndex: "customerId",
      align: "center",
      width: "5%",
    },
    {
      title: "ユーザー名",
      dataIndex: "displayName",
      align: "center",
      width: "80%",
    },
    {
      title: "行動",
      dataIndex: "action",
      align: "center",
      width: "15%",
      render: (admin, record) => (
        <>
          <Popconfirm
            title="削除してもよろしいですか?"
            onConfirm={() => handleAdminDelete(admin.customerId)}
            okText="削除"
            cancelText="閉じる"
            okType="danger"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button className="m-1" icon={<DeleteOutlined />} danger>
              削除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]

  return (
    <>
      <Card title="設定" bordered={false} className="h-full">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card
              title="メッセージの設定"
              bordered={true}
              type="inner"
              className="h-full"
              headStyle={{
                color: "#FFF",
                backgroundColor: "#3dbaeb",
              }}
              style={{
                borderColor: "#3dbaeb",
              }}
            >
              <Row justify="center" className="mb-4">
                <Col>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※以下の設定が設定されていない場合は、LINE側で初期設定のメッセージが送信されます。
                  </Tag>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24}>
                  <Card
                    title="申込者が登録すると送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#94e8ff",
                    }}
                    extra={
                      doneBasicTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() =>
                                showModal(Commons.doneBasicTemplate)
                              }
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                doneBasicTemplate.templateId,
                                Commons.doneBasicTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.doneBasicTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {doneBasicTemplate.templateId &&
                    doneBasicTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {doneBasicTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{doneBasicTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Divider />
                <Col xs={24} xl={12}>
                  <Card
                    title="申込者が不合格すると送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#ff7875",
                    }}
                    extra={
                      failTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() => showModal(Commons.failTemplate)}
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                failTemplate.templateId,
                                Commons.failTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.failTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {failTemplate.templateId && failTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {failTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{failTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Col xs={24} xl={12}>
                  <Card
                    title="申込者が合格すると送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#95de64",
                    }}
                    extra={
                      passTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() => showModal(Commons.passTemplate)}
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                passTemplate.templateId,
                                Commons.passTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.passTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {passTemplate.templateId && passTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {passTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{passTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Divider />
                <Col xs={24}>
                  <Card
                    title="面接時間を選択すると送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#94e8ff",
                    }}
                    extra={
                      appSavedTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() =>
                                showModal(Commons.appSavedTemplate)
                              }
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                appSavedTemplate.templateId,
                                Commons.appSavedTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.appSavedTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {appSavedTemplate.templateId &&
                    appSavedTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {appSavedTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{appSavedTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Divider />
                <Col xs={24}>
                  <Card
                    title="再通知を行うと送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#94e8ff",
                    }}
                    extra={
                      reNotifyTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() =>
                                showModal(Commons.reNotifyTemplate)
                              }
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                reNotifyTemplate.templateId,
                                Commons.reNotifyTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.reNotifyTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {reNotifyTemplate.templateId &&
                    reNotifyTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {reNotifyTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{reNotifyTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Divider />
                <Col xs={24} xl={12}>
                  <Card
                    title="面接から2時間前に送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#94e8ff",
                    }}
                    extra={
                      remindMsgTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() =>
                                showModal(Commons.remindMsgTemplate)
                              }
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                remindMsgTemplate.templateId,
                                Commons.remindMsgTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.remindMsgTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {remindMsgTemplate.templateId &&
                    remindMsgTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {remindMsgTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{remindMsgTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Col xs={24} xl={12}>
                  <Card
                    title="面接から15分前に送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#94e8ff",
                    }}
                    extra={
                      remindCallTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() =>
                                showModal(Commons.remindCallTemplate)
                              }
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                remindCallTemplate.templateId,
                                Commons.remindCallTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() =>
                              showModal(Commons.remindCallTemplate)
                            }
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {remindCallTemplate.templateId &&
                    remindCallTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {remindCallTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{remindCallTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Divider />
                <Col xs={24}>
                  <Card
                    title="身分証明書をリクエストすると送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#94e8ff",
                    }}
                    extra={
                      requestTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() => showModal(Commons.requestTemplate)}
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                requestTemplate.templateId,
                                Commons.requestTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.requestTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {requestTemplate.templateId &&
                    requestTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {requestTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{requestTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Col xs={24} xl={12}>
                  <Card
                    title="身分証明書をもらうと送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#94e8ff",
                    }}
                    extra={
                      certAcceptTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() =>
                                showModal(Commons.certAcceptTemplate)
                              }
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                certAcceptTemplate.templateId,
                                Commons.certAcceptTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() =>
                              showModal(Commons.certAcceptTemplate)
                            }
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {certAcceptTemplate.templateId &&
                    certAcceptTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {certAcceptTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{certAcceptTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Col xs={24} xl={12}>
                  <Card
                    title="2番目の身分証明書をもらうと送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#94e8ff",
                    }}
                    extra={
                      certAcceptOptionalTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() =>
                                showModal(Commons.certAcceptOptionalTemplate)
                              }
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                certAcceptOptionalTemplate.templateId,
                                Commons.certAcceptOptionalTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() =>
                              showModal(Commons.certAcceptOptionalTemplate)
                            }
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {certAcceptOptionalTemplate.templateId &&
                    certAcceptOptionalTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {certAcceptOptionalTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{certAcceptOptionalTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Divider />
                <Col xs={24} xl={12}>
                  <Card
                    title="身分証明書が却下の場合送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#ff7875",
                    }}
                    extra={
                      rejectTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() => showModal(Commons.rejectTemplate)}
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                rejectTemplate.templateId,
                                Commons.rejectTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.rejectTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {rejectTemplate.templateId && rejectTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {rejectTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{rejectTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
                <Col xs={24} xl={12}>
                  <Card
                    title="身分証明書が承認の場合送るメッセージの設定"
                    bordered={true}
                    type="inner"
                    className="h-full"
                    size="small"
                    style={{
                      borderColor: "#95de64",
                    }}
                    extra={
                      acceptTemplate.templateId ? (
                        <>
                          <Tooltip title="変更" placement="left">
                            <Button
                              className="m-1"
                              style={{ color: "#3dbaeb" }}
                              icon={<EditOutlined />}
                              onClick={() => showModal(Commons.acceptTemplate)}
                            />
                          </Tooltip>
                          <Popconfirm
                            title="削除してもよろしいですか?"
                            onConfirm={() =>
                              handleDelete(
                                acceptTemplate.templateId,
                                Commons.acceptTemplate,
                              )
                            }
                            okText="削除"
                            cancelText="閉じる"
                            icon={<QuestionCircleOutlined />}
                          >
                            <Tooltip title="削除" placement="right">
                              <Button
                                className="m-1"
                                style={{ color: "#3dbaeb" }}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title="作成" placement="left">
                          <Button
                            className="m-1"
                            style={{ color: "#3dbaeb" }}
                            icon={<PlusOutlined />}
                            onClick={() => showModal(Commons.acceptTemplate)}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {acceptTemplate.templateId && acceptTemplate.contentText ? (
                      <Text className="whitespace-pre-wrap">
                        {acceptTemplate.contentText}
                      </Text>
                    ) : (
                      <Text className="whitespace-pre-wrap select-none text-gray-500">
                        例：{acceptTemplate.contentText}
                      </Text>
                    )}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              title="チャット返事リスト"
              bordered={true}
              type="inner"
              className="h-full"
              headStyle={{
                color: "#FFF",
                backgroundColor: "#3dbaeb",
              }}
              style={{
                borderColor: "#3dbaeb",
              }}
              bodyStyle={{
                padding: 0,
              }}
              extra={
                <Tooltip title="作成" placement="left">
                  <Button
                    className="m-1"
                    style={{ border: "none", color: "#3dbaeb" }}
                    icon={<PlusOutlined />}
                    onClick={() => showFaqModal(true)}
                  />
                </Tooltip>
              }
            >
              <Table
                bordered
                size="small"
                columns={columns}
                scroll={{ x: true }}
                dataSource={
                  faqTemplate
                    ? faqTemplate.map(template => {
                        return {
                          key: template.templateId,
                          templateId: template.templateId,
                          contentText: template.contentText,
                          action: template,
                        }
                      })
                    : []
                }
                pagination={{
                  responsive: true,
                  position: ["bottomCenter"],
                }}
              />
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              title="管理者リスト"
              bordered={true}
              type="inner"
              className="h-full"
              headStyle={{
                color: "#FFF",
                backgroundColor: "#3dbaeb",
              }}
              style={{
                borderColor: "#3dbaeb",
              }}
              bodyStyle={{
                padding: 0,
              }}
              extra={
                <Tooltip title="追加" placement="left">
                  <Button
                    className="m-1"
                    style={{ border: "none", color: "#3dbaeb" }}
                    icon={<PlusOutlined />}
                    onClick={() => showAdminModal(true)}
                  />
                </Tooltip>
              }
            >
              <Table
                bordered
                size="small"
                columns={adminColumns}
                scroll={{ x: true }}
                dataSource={
                  admins
                    ? admins
                        .filter(admin => admin.isAdmin)
                        .map(admin => {
                          return {
                            key: admin.customerId,
                            customerId: admin.customerId,
                            displayName: admin.displayName,
                            action: admin,
                          }
                        })
                    : []
                }
                pagination={{
                  responsive: true,
                  position: ["bottomCenter"],
                }}
              />
            </Card>
          </Col>
        </Row>
      </Card>
      <Modal
        title="申込者が登録すると送るメッセージの設定"
        visible={isDoneBasicModalVisible}
        onCancel={() => hideModal(Commons.doneBasicTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={doneBasicForm}
              name="doneBasicReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  doneBasicTemplate,
                  Commons.doneBasicTemplate,
                )
              }
              initialValues={{
                doneBasicReplyFormItem:
                  doneBasicTemplate.templateId && doneBasicTemplate.contentText
                    ? doneBasicTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、申込者が登録すると送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="doneBasicReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message:
                          "申込者が登録すると送るメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${doneBasicTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {doneBasicTemplate.templateId &&
                      doneBasicTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="申込者が合格すると送るメッセージの設定"
        visible={isPassModalVisible}
        onCancel={() => hideModal(Commons.passTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={passedForm}
              name="passedReplyForm"
              onFinish={data =>
                templateFormOnFinish(data, passTemplate, Commons.passTemplate)
              }
              initialValues={{
                passedReplyFormItem:
                  passTemplate.templateId && passTemplate.contentText
                    ? passTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、申込者が合格すると送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="passedReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message: "合格のメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${passTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {passTemplate.templateId && passTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="申込者が不合格すると送るメッセージの設定"
        visible={isFailModalVisible}
        onCancel={() => hideModal(Commons.failTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={failedForm}
              name="failedReplyForm"
              onFinish={data =>
                templateFormOnFinish(data, failTemplate, Commons.failTemplate)
              }
              initialValues={{
                failedReplyFormItem:
                  failTemplate.templateId && failTemplate.contentText
                    ? failTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、申込者が不合格すると送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="failedReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message: "不合格のメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${failTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {failTemplate.templateId && failTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="チャット返事の設定"
        visible={isFaqModalVisible}
        onCancel={hideFaqModal}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form form={faqForm} name="faqReplyForm" onFinish={faqFormOnFinish}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※この返事を面接者がチャット画面から使います
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="faqReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message: "返事を入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder="返事を入力してください"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {faqStatus.isNew ? "作成" : "変更"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="面接時間を選択すると送るメッセージの設定"
        visible={isAppSavedModalVisible}
        onCancel={() => hideModal(Commons.appSavedTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={appSavedForm}
              name="appSavedReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  appSavedTemplate,
                  Commons.appSavedTemplate,
                )
              }
              initialValues={{
                appSavedReplyFormItem:
                  appSavedTemplate.templateId && appSavedTemplate.contentText
                    ? appSavedTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、面接時間を選択すると送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="appSavedReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message:
                          "面接時間を選択すると送るメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${appSavedTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {appSavedTemplate.templateId &&
                      appSavedTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="再通知を行うと送るメッセージの設定"
        visible={isReNotifyModalVisible}
        onCancel={() => hideModal(Commons.reNotifyTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={reNotifyForm}
              name="reNotifyReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  reNotifyTemplate,
                  Commons.reNotifyTemplate,
                )
              }
              initialValues={{
                reNotifyReplyFormItem:
                  reNotifyTemplate.templateId && reNotifyTemplate.contentText
                    ? reNotifyTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、再通知を行うと送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="reNotifyReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message:
                          "再通知を行うと送るメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${reNotifyTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {reNotifyTemplate.templateId &&
                      reNotifyTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="面接から2時間前に送るメッセージの設定"
        visible={isRemindMsgModalVisible}
        onCancel={() => hideModal(Commons.remindMsgTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={remindMsgForm}
              name="remindMsgReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  remindMsgTemplate,
                  Commons.remindMsgTemplate,
                )
              }
              initialValues={{
                remindMsgReplyFormItem:
                  remindMsgTemplate.templateId && remindMsgTemplate.contentText
                    ? remindMsgTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、面接から2時間前に送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="remindMsgReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message:
                          "面接から2時間前に送るメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${remindMsgTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {remindMsgTemplate.templateId &&
                      remindMsgTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="面接から15分前に送るメッセージの設定"
        visible={isRemindCallModalVisible}
        onCancel={() => hideModal(Commons.remindCallTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={remindCallForm}
              name="remindCallReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  remindCallTemplate,
                  Commons.remindCallTemplate,
                )
              }
              initialValues={{
                remindCallReplyFormItem:
                  remindCallTemplate.templateId &&
                  remindCallTemplate.contentText
                    ? remindCallTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、面接から15分前に送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="remindCallReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message:
                          "面接から15分前に送るメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${remindCallTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {remindCallTemplate.templateId &&
                      remindCallTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="身分証明書をリクエストすると送るメッセージの設定"
        visible={isRequestModalVisible}
        onCancel={() => hideModal(Commons.requestTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={requestForm}
              name="requestReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  requestTemplate,
                  Commons.requestTemplate,
                )
              }
              initialValues={{
                requestReplyFormItem:
                  requestTemplate.templateId && requestTemplate.contentText
                    ? requestTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、身分証明書をリクエストすると送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="requestReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message:
                          "身分証明書リクエストのメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${requestTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {requestTemplate.templateId && requestTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="身分証明書をもらうと送るメッセージの設定"
        visible={isCertAcceptModalVisible}
        onCancel={() => hideModal(Commons.certAcceptTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={certAcceptForm}
              name="certAcceptReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  certAcceptTemplate,
                  Commons.certAcceptTemplate,
                )
              }
              initialValues={{
                certAcceptReplyFormItem:
                  certAcceptTemplate.templateId &&
                  certAcceptTemplate.contentText
                    ? certAcceptTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、身分証明書をもらうと送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="certAcceptReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message:
                          "身分証明書をもらうと送るメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${certAcceptTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {certAcceptTemplate.templateId &&
                      certAcceptTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="2番目の身分証明書をもらうと送るメッセージの設定"
        visible={isCertAcceptOptionalModalVisible}
        onCancel={() => hideModal(Commons.certAcceptOptionalTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={certAcceptOptionalForm}
              name="certAcceptOptionalReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  certAcceptOptionalTemplate,
                  Commons.certAcceptOptionalTemplate,
                )
              }
              initialValues={{
                certAcceptOptionalReplyFormItem:
                  certAcceptOptionalTemplate.templateId &&
                  certAcceptOptionalTemplate.contentText
                    ? certAcceptOptionalTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、2番目の身分証明書をもらうと送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="certAcceptOptionalReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message:
                          "2番目の身分証明書をもらうと送るメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${certAcceptOptionalTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {certAcceptOptionalTemplate.templateId &&
                      certAcceptOptionalTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="身分証明書が却下の場合送るメッセージの設定"
        visible={isRejectModalVisible}
        onCancel={() => hideModal(Commons.rejectTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={rejectForm}
              name="rejectReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  rejectTemplate,
                  Commons.rejectTemplate,
                )
              }
              initialValues={{
                rejectReplyFormItem:
                  rejectTemplate.templateId && rejectTemplate.contentText
                    ? rejectTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、身分証明書が却下の場合送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="rejectReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message: "却下のメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${rejectTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {rejectTemplate.templateId && rejectTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="身分証明書が承認の場合送るメッセージの設定"
        visible={isAcceptModalVisible}
        onCancel={() => hideModal(Commons.acceptTemplate)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={acceptForm}
              name="acceptReplyForm"
              onFinish={data =>
                templateFormOnFinish(
                  data,
                  acceptTemplate,
                  Commons.acceptTemplate,
                )
              }
              initialValues={{
                acceptReplyFormItem:
                  acceptTemplate.templateId && acceptTemplate.contentText
                    ? acceptTemplate.contentText
                    : null,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※このメッセージは、身分証明書が承認の場合送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="acceptReplyFormItem"
                    rules={[
                      {
                        required: true,
                        message: "承認のメッセージを入力してください",
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 10, maxRows: 15 }}
                      maxLength={5000}
                      placeholder={`例：${acceptTemplate.contentText}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      {acceptTemplate.templateId && acceptTemplate.contentText
                        ? "変更"
                        : "作成"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="管理者の追加"
        visible={isAdminModalVisible}
        onCancel={hideAdminModal}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              form={adminForm}
              name="adminForm"
              onFinish={adminFormOnFinish}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Tag
                    color="orange"
                    style={{ fontSize: "14px" }}
                    className="p-3 whitespace-pre-wrap"
                  >
                    ※申込者のチャット通知がこのユーザーに送信されます
                  </Tag>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="管理者"
                    name="adminFormItem"
                    className="block"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "管理者を選択してください",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="管理者を選択してください"
                    >
                      {admins
                        .filter(admin => admin.isAdmin === false)
                        .map(admin => (
                          <Option
                            key={admin.customerId + ""}
                            value={admin.customerId + ""}
                          >
                            {admin.displayName}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      追加
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default withRouter(Settings)
