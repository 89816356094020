import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import { Button, Form, Input, message, Space, Typography } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import * as Commons from "common/common"

const Login = props => {
  const { history } = props
  const [form] = Form.useForm()
  const isMountedRef = Commons.useIsMountedRef()
  const { Text } = Typography

  const [isLoginLoading, setIsLoginLoading] = useState(false)

  const checkSession = useCallback(() => {
    Commons.axiosInstance
      .get(Commons.apiCheckSession)
      .then(response => {
        history.push(Commons.rootURL)
      })
      .catch(error => {
        if (error.response.status === 403) {
          return
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
  }, [history])

  useEffect(checkSession, [])

  const onFinish = data => {
    setIsLoginLoading(true)

    const postData = {
      username: data["loginUsername"],
      password: data["loginPassword"],
    }

    Commons.axiosInstance
      .post(Commons.apiLogin, postData)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successLoginMsg)
          history.push(Commons.rootURL)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorLoginMismatchMsg)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoginLoading(false)
        }
      })
  }

  const testLogin = () => {
    setIsLoginLoading(true)

    const postData = {
      username: "admin",
      password: "password",
    }

    Commons.axiosInstance
      .post(Commons.apiLogin, postData)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successLoginMsg)
          history.push(Commons.rootURL)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorLoginMismatchMsg)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoginLoading(false)
        }
      })
  }

  return (
    <div className="flex h-screen">
      <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-5 m-auto">
        <div className="flex" style={{ marginBottom: "1rem" }}>
          <img src="logo.svg" alt="" className="mx-auto w-3/4" />
        </div>
        <div className="text-center mb-10">
          <Text style={{ fontSize: 20 }}>キャスト面接管理システム</Text>
        </div>
        <Form name="loginForm" onFinish={onFinish} size="large" form={form}>
          <Form.Item
            name="loginUsername"
            rules={[
              {
                required: true,
                message: "ユーザー名を入力してください",
              },
            ]}
          >
            <Input
              name="loginUsername"
              autoCapitalize="none"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="ユーザー名"
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="loginPassword"
            rules={[
              {
                required: true,
                message: "パスワードを入力してください",
              },
            ]}
          >
            <Input
              name="loginPassword"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="パスワード"
              allowClear
            />
          </Form.Item>
          <Form.Item className="text-center">
            <Space direction="vertical">
              <Button type="primary" htmlType="submit" loading={isLoginLoading}>
                ログイン
              </Button>
              <Button
                type="primary"
                className="login-form-button"
                onClick={testLogin}
                loading={isLoginLoading}
              >
                テストログイン
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <div className="flex mb-10"></div>
      </div>
    </div>
  )
}

export default withRouter(Login)
